import baseApi from "./baseApi";
import { useAuthStore } from "../store";



export const fetchIntegrationsApi = async () => {
  const { user, token } = useAuthStore.getState();
  try {
    const response = await baseApi.get(
      `/integration/check?accountId=${user.accountId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) throw new Error("Failed to fetch integrations");
    const jsonRes = await response.json();
    return jsonRes.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createIntegrationApi = async (integration) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;
  try {
    const integrationData = {
      accountId: user.accountId,
      name: integration.name,
      parameters: integration.parameters.map(param => ({
        name: param.name,
        value: param.value
      }))
    };

    const response = await baseApi.post('/brandintegration', integrationData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const jsonRes = await response.json();
    if (!response.ok) throw new Error(jsonRes.message || "Failed to create integration");
    return jsonRes;
  } catch (err) {
    console.error('Error creating integration:', err);
    throw err;
  }
};

export const updateIntegrationApi = async (id, integration) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const integrationData = {
      parameters: integration.parameters.map(param => ({
        name: param.name,
        value: param.value
      }))
    };

    const response = await baseApi.put(`/brandintegration/${id}`, integrationData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    if (!response.ok) throw new Error(jsonRes.message || "Failed to update integration");
    return jsonRes;
  } catch (err) {
    console.error('Error updating integration:', err);
    throw err;
  }
};

export const deleteIntegrationApi = async (id) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const response = await baseApi.delete(`/brandintegration/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    if (!response.ok) throw new Error(jsonRes.message || "Failed to delete integration");
    return jsonRes;
  } catch (err) {
    console.error('Error deleting integration:', err);
    throw err;
  }
};