import React from "react";

const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="3 6 5 6 21 6"></polyline>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </svg>
);

const KnowledgeBaseTable = ({ handleOnClick, entries, handleDelete }) => {
  return (
    <div className="overflow-x-auto p-4">
      <div className="min-w-full  overflow-hidden rounded-md bg-white  border border-disableColor">
        <div className="px-4 py-4">
          <div className="flex border-b border-disableColor">
            <div className=" flex-1 px-6 py-3 text-left text-sm font-semibold text-textPrimary uppercase tracking-wider">
              Title
            </div>
            <div className="flex-1 px-6 py-3 text-left text-sm font-semibold text-textPrimary uppercase tracking-wider">
              Category
            </div>
            <div className="flex-1 px-6 py-3 text-left text-sm font-semibold text-textPrimary uppercase tracking-wider">
              Last Updated
            </div>
            <div className="flex-1 px-6 py-3 text-left text-sm font-semibold text-textPrimary uppercase tracking-wider">
              Status
            </div>
            <div className="w-24 px-6 py-3 text-center text-sm font-semibold text-textPrimary uppercase tracking-wider">
              Delete
            </div>
          </div>
          <div className="divide-y divide-gray-200">
            {entries?.map((entry) => (
              <div
                key={entry._id}
                className="flex hover:bg-gray-50 transition-colors duration-200 cursor-pointer"
                onClick={() => handleOnClick(entry._id)}
              >
                <div className="flex-1 px-6 py-4 whitespace-nowrap overflow-hidden">
                  <div className="text-sm font-medium text-gray-900 truncate max-w-xs flex-shrink-0">
                    {entry.title}
                  </div>
                </div>
                <div className="flex-1 px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-textPrimary">
                    {entry.parameters[0]?.type || "Uncategorized"}
                  </div>
                </div>
                <div className="flex-1 px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-textPrimary">
                    {new Date(entry.updatedAt).toLocaleDateString()}
                  </div>
                </div>
                <div className="flex-1 px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-textPrimary">
                    {(entry.status && entry.status != "") &&
                      (new Date(entry.createdAt) > new Date("Fri Aug 09 2024 18:49:07 GMT+0530 (India Standard Time)"))
                      ? entry.status
                      : "CREATED"}
                  </div>
                </div>
                <div className="w-24 px-6 py-4 whitespace-nowrap text-center text-sm text-textPrimary">
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDelete(entry._id);
                    }}
                    className="text-red-600 hover:text-red-800 focus:outline-none"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseTable;