import React, { useState } from "react";
import GoogleLoginButton from "../../../../components/GoogleLoginButton";
import { useSignup } from "../../../../hooks/useSignup";
import { GoogleLogin } from "@react-oauth/google";
const SignupForm = () => {
  const {
    formState,
    setFormState,
    passwordStrength,
    setPasswordStrength,
    error,
    onError,
    handleLogin,
    handleGoogleLogin,
  } = useSignup();
  return (
    <>
      <form className="flex flex-col w-full" onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          className="border px-5 py-2 rounded-md"
          value={formState.email}
          onChange={(e) =>
            setFormState({ ...formState, email: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="Full Name"
          className="border px-5 py-2 rounded-md mt-5"
          value={formState.fullName}
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
        />
        <input
          type="password"
          placeholder="Password"
          className="border px-5 py-2 rounded-md mt-5"
          value={formState.password}
          onChange={(e) => {
            const pass = e.target.value;
            const special = /[^A-Za-z0-9]/.test(pass);
            const uppercase = /[A-Z]/.test(pass);
            const lowercase = /[a-z]/.test(pass);
            const number = /[0-9]/.test(pass);
            setPasswordStrength({ special, uppercase, lowercase, number });
            setFormState({ ...formState, password: pass });
          }}
        />
        <div className="flex flex-row my-5 ">
          <div className="flex basis-1/2 flex-col gap-2">
            <div
              className={`text-xs font-medium leading-none ${passwordStrength.special ? "text-limegreen" : "text-stone-400"
                }`}
            >
              {passwordStrength.special ? "✔" : ""} Special Character
            </div>
            <div
              className={`text-xs font-medium leading-none ${passwordStrength.uppercase ? "text-limegreen" : "text-stone-400"
                }`}
            >
              {passwordStrength.uppercase ? "✔" : ""} Uppercase Character
            </div>
          </div>
          <div className="flex basis-1/2 flex-col gap-2">
            <div
              className={`text-xs font-medium leading-none ${passwordStrength.lowercase ? "text-limegreen" : "text-stone-400"
                }`}
            >
              {passwordStrength.lowercase ? "✔" : ""} Lowercase letter
            </div>
            <div
              className={`text-xs font-medium leading-none ${passwordStrength.number ? "text-limegreen" : "text-stone-400"
                }`}
            >
              {passwordStrength.number ? "✔" : ""} Number
            </div>
          </div>
        </div>
        <div className="mx-5 ml-0 px-0 text-red-900">{error}</div>
        <button className="bg-yellow text-ascentColor font-bold px-5 py-2 rounded-md mt-5">
          SIGN UP
        </button>
      </form>

      <div className="flex items-center my-10 w-full  text-textLights ">
        <div className="flex-grow h-[1px] bg-textLight"></div>
        <span className="mx-4 text-center text-xs font-medium">OR</span>
        <div className="flex-grow h-[1px] bg-textLight"></div>
      </div>
      <div className="flex justify-center w-full">
        <GoogleLogin
          style={{ width: "100%" }}
          onSuccess={handleGoogleLogin}
          onError={onError}
          text="signup_with"
        >
        </GoogleLogin>
      </div>

      {/* <GoogleLoginButton
        handleGoogleLogin={handleGoogleLogin}
        onError={onError}
      /> */}
    </>
  );
};

export default SignupForm;
