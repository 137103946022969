import { create } from "zustand";
import { persist } from "zustand/middleware";
import { fetchIntegrationsApi, createIntegrationApi, updateIntegrationApi, } from "api/integrationApi";
import { toast } from "react-toastify";

const initialState = {
    integrations: [],
};

export const useIntegrationStore = create(
    persist(
        (set) => ({
            ...initialState,
            updateIntegrations: (data) => {
                set({ integrations: data });
            },
            fetchIntegrations: async () => {
                try {
                    const jsonRes = await fetchIntegrationsApi();
                    set({ integrations: jsonRes });
                } catch (err) {
                    console.error(err);
                    toast.error("Failed to fetch integrations");
                }
            },
            createIntegration: async (integration) => {
                try {
                    const jsonRes = await createIntegrationApi(integration);
                    // set((state) => ({ integrations: [...state.integrations, jsonRes.data] }));
                    const fetchJsonRes = await fetchIntegrationsApi();
                    set({ integrations: fetchJsonRes });
                    toast.success("Integration created successfully");
                } catch (err) {
                    console.error(err);
                    toast.error("Failed to create integration");
                }
            },
            updateIntegration: async (id, integration) => {
                try {
                    const jsonRes = await updateIntegrationApi(id, integration);
                    const fetchJsonRes = await fetchIntegrationsApi();
                    set({ integrations: fetchJsonRes.data });
                    toast.success("Integration updated successfully");
                } catch (err) {
                    console.error(err);
                    toast.error("Failed to update integration");
                }
            },
            updateState: (key, val) => {
                set((state) => ({
                    ...state,
                    [key]: val,
                }));
            },
        }),
        {
            name: "integration",
        }
    )
);