class WebSocketService {
    constructor(url) {
        this.url = url;
        this.ws = null;
        this.initializeConnection();
    }

    async initializeConnection() {
        try {
            await this.connect();
        } catch (error) {
            console.error('Failed to connect:', error);
            // setTimeout(() => this.initializeConnection(), 5000); // Retry after delay
        }
    }

    connect() {
        return new Promise((resolve, reject) => {
            if (this.ws !== null && this.ws.readyState === WebSocket.OPEN) {
                resolve();
                return;
            }

            this.ws = new WebSocket(this.url);

            this.ws.onopen = () => {
                console.log('WebSocket connected');
                resolve();
            };
            this.ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                reject(error);
            };
            this.ws.onclose = (event) => {
                console.log('WebSocket disconnected:', event.reason);
                this.ws = null; // Clear the reference to allow reconnection
                reject(new Error('WebSocket closed'));
            };
        });
    }

    async sendMessage(message) {
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            console.log('Connection is closed or not yet open, reconnecting...');
            await this.connect(); // Ensure connection is open or wait for it
        }
        this.ws.send(message);
    }

    onMessage(callback) {
        if (this.ws) {
            this.ws.onmessage = (event) => callback(event.data);
        }
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
        }
    }
    isConnected() {
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }
}
export default WebSocketService;