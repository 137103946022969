import React, { useState } from "react";
import { useKnowledgeBaseStore } from "../../../../../../store";
import { useRulesStore } from "../../../../../../store";
import { INTEGRATION_LIST } from "utils/constant";
import { shallow } from "zustand/shallow";

const WorkflowComponent = () => {
  const { knowledgeBases } = useKnowledgeBaseStore((state) => ({
    knowledgeBases: state.knowledgeBases,
  }), shallow);
  const { form, updateForm } = useRulesStore((state) => ({
    form: state.form,
    updateForm: state.updateForm,
  }), shallow);

  return (
    <div className="m-4">
      <p className="text-md font-semibold mb-2">Workflow apps</p>
      <div className="w-full p-4 bg-white rounded-lg border border-gray-300">
        {form?.tools?.length > 0 ? (
          <>
            <h1 className=" font-medium mb-2 text-textLight text-sm">Tools</h1>

            {form?.tools?.map((item, index) => (
              <div key={index} className="grid grid-cols-2 gap-6 mb-4">
                <div>
                  <div className="w-full  py-2"> {item.name} </div>
                </div>
                <div>
                  <div className="w-full py-2">
                    {item.actions
                      .map(
                        (action) =>
                          INTEGRATION_LIST.find(
                            (integration) => integration.name == item.name
                          )?.implementedFunctionality.find(
                            (field) => field.name == action
                          )?.displayName
                      )
                      .join(", ")}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <h1 className=" font-medium text-textLight text-sm">Tools</h1>
            <div>-</div>
          </>
        )}

        <div className="mt-2">
          {" "}
          <h1 className="text-sm font-medium text-textLight">Knowledge Base</h1>
          <div className="w-full">
            {knowledgeBases?.find((kb) => kb._id === form.knowledgeBaseId)
              ?.title || "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowComponent;
