import React, { useState } from "react";
import { Link } from "react-router-dom";

const TopBarContent = ({ handleSaveEntry }) => {
  const [isSaving, setIsSaving] = useState(false);

  const onSaveEntry = async () => {
    setIsSaving(true);
    try {
      await handleSaveEntry();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div>
        <Link
          to="/dashboard/knowledgeBase"
          className="text-2xl font-semibold text-blue-700"
        >
          Knowledge Base
        </Link>
        <span className="text-xl font-semibold text-gray-800">{" > "}</span>
        <Link
          to="/dashboard/knowledgeBase/create"
          className="text-2xl font-medium text-gray-800"
        >
          Create Entry
        </Link>
      </div>
      <div className="flex gap-2">
        <button
          onClick={onSaveEntry}
          disabled={isSaving}
          className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-6 rounded-[2rem] transition-colors duration-150 ease-in-out"
        >
          {isSaving && (
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
          )}
          <b className="text-sm text-white font-semibold">
            {isSaving ? 'Saving...' : 'Save Entry'}
          </b>
        </button>
      </div>
    </>
  );
};

export default TopBarContent;
