import { create } from "zustand";
import { fetchKnowledgeBaseApi } from "../api/knowledgeBaseApi";
import { toast } from "react-toastify";

const initialState = {
  knowledgeBases: [],
  form: {
    title: "",
    parameters: [""],
  },
  files: {},  // {id: { key: string, file: File}}

};

export const useKnowledgeBaseStore = create(
  (set, get) => ({
    ...initialState,
    updateKnowledgeBases: (data) => {
      set((state) => ({
        ...state,
        knowledgeBases: data,
      }));
    },
    fetchKnowledgeBases: async (accountId) => {
      try {
        const jsonRes = await fetchKnowledgeBaseApi(accountId);
        set((state) => ({ ...state, knowledgeBases: jsonRes.data }));
      } catch (err) {
        console.log(err);
      }
    },
    updateForm: (key, val) => {
      set((state) => ({
        ...state,
        form: { ...state.form, [key]: val },
      }));
    },
    resetForm: () => {
      set((state) => ({
        ...state,
        form: initialState.form,
      }));
    },
    updateFullForm: (data) => {
      set((state) => ({
        ...state,
        form: data,
      }));
    },

    checkFormFilled: () => {
      const state = get();
      let allFilled = true;
      let requiredString = "";

      if (!state.form.title || state.form.title.trim() === "") {
        requiredString += "Title, ";
        allFilled = false;
      }

      if (!state.form.parameters || state.form.parameters.length === 0) {
        requiredString += "Parameters, ";
        allFilled = false;
      } else {
        state.form.parameters.forEach((param, index) => {
          if (!param.type || param.type === "") {
            requiredString += `Parameter ${index + 1} Type, `;
            allFilled = false;
          }
          if (!param.value || param.value === "" || param.value === "{}") {
            requiredString += `Parameter ${index + 1} Value, `;
            allFilled = false;
          } else {
            try {
              const parsedValue = JSON.parse(param.value);
              if (param.type === "WEBSITE") {
                if (!parsedValue.base_url || parsedValue.base_url.trim() === "") {
                  requiredString += `Parameter ${index + 1} Base URL, `;
                  allFilled = false;
                }
                if (!parsedValue.prefix_url || parsedValue.prefix_url.trim() === "") {
                  requiredString += `Parameter ${index + 1} Prefix URL, `;
                  allFilled = false;
                }
                if (!parsedValue.max_depth && parsedValue.max_depth !== 0) {
                  requiredString += `Parameter ${index + 1} Max Depth, `;
                  allFilled = false;
                }
              }
            } catch (error) {
              console.error("Error parsing parameter value:", error);
              requiredString += `Parameter ${index + 1} Value (invalid format), `;
              allFilled = false;
            }
          }
        });
      }

      if (!allFilled) {
        requiredString = requiredString.slice(0, -2); // Remove trailing comma and space
        toast.warn(`${requiredString} are required.`);
      }
      return allFilled;
    },
    updateFiles: (id, file) => {
      const state = get();
      set(
        {
          files: {
            ...state.files,
            [id]: file
          }
        }
      )

    },
    removeFile: (id) => {
      set((state) => {
        const newFiles = { ...state.files };
        delete newFiles[id];
        return { ...state, files: newFiles };
      });
    },
    resetFiles: () => {
      set((state) => ({
        ...state,
        files: initialState.files,
      }));
    },

  })
);