export const INTEGRATION_LIST = [
  {
    name: "shopify",
    logo: "https://cdn.worldvectorlogo.com/logos/shopify.svg",
    isConnected: false,
    requiredFields: [
      { displayName: "Shop Name", name: "shop_name", value: "" },
      { displayName: "API Token", name: "token", value: "" },
    ],
    implementedFunctionality: [
      {
        name: "shopifyOrderStatusById",
        displayName: "Shopify Order Status",
      },
      {
        name: "shopifyOrderDetailsById",
        displayName: "Shopify Order Detail",
      },
    ],
  },
  {
    name: "recharge",
    logo: "https://getrecharge.com/wp-content/uploads/2021/07/favicon.png",
    isConnected: false,
    requiredFields: [{ displayName: "API Token", name: "apiToken", value: "" }],
  },
  {
    name: "skio",
    logo: "https://media.licdn.com/dms/image/v2/D4D0BAQFvPOSB0l9NXg/company-logo_200_200/company-logo_200_200/0/1706754495182/skio_logo?e=1731542400&v=beta&t=HKZju-S7u2OlUerP8ZPHo4P-YfMcCIvd2aaX8dqEtaY",
    isConnected: false,
    requiredFields: [
      { displayName: "API Key", name: "apiKey", value: "" },
      { displayName: "Client ID", name: "clientId", value: "" },
    ],
  },
  {
    name: "notion",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png",
    isConnected: false,
    requiredFields: [
      { displayName: "Integration Token", name: "integrationToken", value: "" },
    ],
  },
];


export const KNOWLEDGE_BASE_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DocxReader
  "application/epub+zip", // EpubReader
  "application/x-hwp", // HWPReader
  "image/jpeg", // ImageReader
  "application/x-ipynb+json", // IPYNBReader
  "text/markdown", // MarkdownReader
  "application/vnd.mozilla.mbox", // MboxReader
  "text/csv", // PandasCSVReader
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // PandasExcelReader
  "application/pdf", // PDFReader
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PptxReader
  "video/mp4", // VideoAudioReader
  "application/json", // JSON files
  "image/png", // PNG images
]
