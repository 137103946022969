import baseApi from "./baseApi";
import { useAuthStore } from "../store";
export const fetchRulesApi = async () => {
  const auth = useAuthStore.getState();
  const user = auth.user;
  if (user === null) return;
  const token = auth.token;
  try {
    const response = await baseApi.get(`/rule/brand/${user.accountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRuleApi = async (rule) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    if (rule.knowledgeBaseId === '') {
      delete rule.knowledgeBaseId;
    }
    Object.assign(rule, { accountId: user.accountId });
    const response = await baseApi.post('/rule', rule, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const jsonRes = await response.json();
    if (!response.ok) throw new Error(jsonRes.message || "Failed to create rule");
    return jsonRes;
  } catch (err) {
    console.error('Error creating rule:', err);
    throw err;
  }
};

export const updateRuleApi = async (rule, id) => {
  const auth = useAuthStore.getState();
  const user = auth.user;
  if (user === null) return;
  if (rule.knowledgeBaseId === '') {
    rule.knowledgeBaseId = null;
  }
  const token = auth.token;
  try {
    const response = await baseApi.put(`/rule/${id}`, rule, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
    throw err;
  }
};



export const deleteRuleApi = async (id) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const response = await baseApi.delete(`/rule/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    if (!response.ok) throw new Error(jsonRes.message || "Failed to delete rule");
    return jsonRes;
  } catch (err) {
    console.error('Error deleting rule:', err);
    throw err;
  }
};


export const ruleToggleApi = async (id) => {
  const auth = useAuthStore.getState();
  const user = auth.user;
  if (user === null) return;
  const token = auth.token;
  try {
    const response = await baseApi.put(
      `/rule/toggle/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
    throw err;
  }
};