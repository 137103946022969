import React, { useEffect, useState } from "react";
import { useKnowledgeBaseStore } from "../../../../../store";
import FileUpload from "../FileUpload";
import { shallow } from "zustand/shallow";

const ParameterInput = ({
  index,
  parameter,
  updateParameter,
  removeParameter,
}) => {
  const [parameterType, setParameterType] = useState(parameter.type || "");
  const [parameterValue, setParameterValue] = useState(parameter.value || "");

  useEffect(() => {
    setParameterType(parameter.type);
    setParameterValue(parameter.value);
  }, [parameter]);

  const parseValue = (value) => {
    try {
      return JSON.parse(value);
    } catch {
      return {};
    }
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setParameterType(newType);
    // Reset value when type changes
    updateParameter(index, { type: newType, value: JSON.stringify({}) });
  };

  const handleValueChange = (e, field) => {
    const value = e.target.value;
    const parsedValue = parseValue(parameterValue);
    if (parameterType === "WEBPAGE") {
      parsedValue.url = value;
    } else if (parameterType === "TEXT_INPUT") {
      parsedValue.text = value;
    } else if (parameterType === "WEBSITE") {
      parsedValue[field] = value;
    }

    const newValue = JSON.stringify(parsedValue);
    setParameterValue(newValue);
    updateParameter(index, { type: parameterType, value: newValue });
  };
  const handleFileChange = (key) => {
    const parsedValue = parseValue(parameterValue);
    parsedValue.key = key;
    const newValue = JSON.stringify(parsedValue);
    setParameterValue(newValue);
    updateParameter(index, { type: parameterType, value: newValue });
  };
  return (
    <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-sm">
      <select
        value={parameterType}
        onChange={handleTypeChange}
        className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="">Select Parameter Type</option>
        <option value="WEBPAGE">Webpage</option>
        <option value="TEXT_INPUT">Text Input</option>
        <option value="WEBSITE">Website</option>
        <option value="FILE_UPLOAD">File Upload</option>
      </select>
      {parameterType === "WEBPAGE" && (
        <input
          type="text"
          value={parseValue(parameterValue)?.url || ""}
          onChange={(e) => handleValueChange(e)}
          placeholder="Enter URL"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      )}
      {parameterType === "TEXT_INPUT" && (
        <textarea
          value={parseValue(parameterValue)?.text || ""}
          onChange={(e) => handleValueChange(e)}
          placeholder="Enter text"
          rows="3"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        ></textarea>
      )}
      {parameterType === "WEBSITE" && (
        <div>
          <input
            type="text"
            value={parseValue(parameterValue)?.base_url || ""}
            onChange={(e) => handleValueChange(e, 'base_url')}
            placeholder="Enter Base URL"
            className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
          <input
            type="text"
            value={parseValue(parameterValue)?.prefix_url || ""}
            onChange={(e) => handleValueChange(e, 'prefix_url')}
            placeholder="Enter Prefix URL"
            className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
          <input
            type="number"
            value={parseValue(parameterValue)?.max_depth || ""}
            onChange={(e) => handleValueChange(e, 'max_depth')}
            placeholder="Enter Max Depth ( Max: 10)"
            min="1"
            max="10"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      )}
      {parameterType === "FILE_UPLOAD" && (
        <FileUpload handleFileChange={handleFileChange} />
      )}
      <button
        onClick={() => removeParameter(index)}
        className="mt-2 text-red-500 hover:text-red-700 text-sm font-semibold"
      >
        Remove Parameter
      </button>
    </div>
  );
};

const KnowledgeCreationForm = () => {
  const { form, updateForm } = useKnowledgeBaseStore((state) => ({
    form: state.form,
    updateForm: state.updateForm,
  }), shallow);
  const [parameters, setParameters] = useState([{ type: "", value: "" }]);

  const addParameter = () => {
    setParameters([...parameters, { type: "", value: "" }]);
  };

  const updateParameter = (index, newParameter) => {
    const newParameters = [...parameters];
    newParameters[index] = newParameter;
    setParameters(newParameters);
    updateForm("parameters", newParameters);
  };

  const removeParameter = (index) => {
    const newParameters = parameters.filter((_, i) => i !== index);
    setParameters(newParameters);
    updateForm("parameters", newParameters);
  };

  useEffect(() => {
    setParameters(form.parameters || [{ type: "", value: "" }]);
  }, [form.parameters]);



  return (
    <div className="m-4">
      <p className="text-md font-semibold mb-2">Knowledge Base Information</p>
      <div className="w-full mx-auto p-4 bg-white rounded-lg border border-gray-300">
        <div className="mb-6">
          <label className="block text-sm font-medium text-textPrimary mb-1">
            Title
          </label>
          <input
            type="text"
            value={form.title}
            onChange={(e) => updateForm("title", e.target.value)}
            placeholder="Enter the title of the knowledge base entry"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          {parameters.map((parameter, index) => (
            <ParameterInput
              key={index}
              index={index}
              parameter={parameter}
              updateParameter={updateParameter}
              removeParameter={removeParameter}
            />
          ))}
          <button
            onClick={addParameter}
            className="pb-2 text-buttonBlue font-semibold"
          >
            + Add Parameter
          </button>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeCreationForm;