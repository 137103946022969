export function calculateHoursAgo(dateString) {
  // Parse the date string (assuming it's in a format compatible with new Date())
  const date = new Date(dateString);

  // Get the current time in milliseconds
  const now = new Date().getTime();

  // Calculate the difference in milliseconds
  const diffInMs = now - date.getTime();

  // Convert milliseconds to hours and round down
  const hoursAgo = Math.floor(diffInMs / (1000 * 60 * 60));

  return hoursAgo;
}
