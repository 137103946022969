import React, { useState } from "react";
import { calculateHoursAgo } from "../../../../../utils/time";
import { ruleToggleApi, deleteRuleApi } from "../../../../../api/ruleApi";
import { useRulesStore } from "../../../../../store";
import { toast } from "react-toastify";

const ToggleButton = ({ isActive, onToggle, isLoading }) => {
  return (
    <button
      onClick={onToggle}
      disabled={isLoading}
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${isLoading ? "bg-gray-400" : isActive ? "bg-green-500" : "bg-gray-300"
        }`}
    >
      <span
        className={`absolute inset-y-0 left-0 flex items-center justify-center w-6 h-6 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${isActive ? "translate-x-5" : "translate-x-0"
          }`}
      >
        {isLoading ? (
          <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        ) : isActive ? (
          "✓"
        ) : (
          "✗"
        )}
      </span>
    </button>
  );
};

const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="3 6 5 6 21 6"></polyline>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </svg>
);

const RuleTable = ({ handleOnClick, rules }) => {
  const { fetchRules } = useRulesStore();
  const [togglingRules, setTogglingRules] = useState({});

  const toggleStatus = async (ruleId) => {
    setTogglingRules(prev => ({ ...prev, [ruleId]: true }));
    try {
      await ruleToggleApi(ruleId);
      await fetchRules();
      toast.success("Rule status updated successfully");
    } catch (e) {
      console.error("Error toggling status:", e);
      toast.error("Failed to update rule status");
    } finally {
      setTogglingRules(prev => ({ ...prev, [ruleId]: false }));
    }
  };

  const handleDelete = async (ruleId, event) => {
    event.stopPropagation();
    try {
      await deleteRuleApi(ruleId);
      await fetchRules();
      toast.success("Rule deleted successfully");
    } catch (e) {
      console.error("Error deleting rule:", e);
      toast.error("Failed to delete rule");
    }
  };

  return (
    <div className="overflow-x-auto px-4">
      <div className="min-w-full rounded-md overflow-hidden bg-white border border-disableColor">
        <div className="p-4">
          <div className="grid grid-cols-6 gap-4 px-6 py-3 text-xs font-medium text-textPrimary uppercase tracking-wider border-b border-disableColor">
            <div className="text-left font-semibold text-sm">Rule</div>
            <div className="text-left font-semibold text-sm">Rule Type</div>
            <div className="text-center font-semibold text-sm">
              Last Triggered
            </div>
            <div className="text-center font-semibold text-sm">Last Edited</div>
            <div className="text-center font-semibold text-sm">Status</div>
            <div className="text-center font-semibold text-sm">Delete</div>
          </div>
          <div className="divide-y divide-disableColor">
            {rules?.map((rule) => (
              <div
                key={rule._id}
                className="grid grid-cols-6 gap-4 px-6 py-4 hover:bg-gray-50 transition-colors duration-200 cursor-pointer"
                onClick={() => handleOnClick(rule._id)}
              >
                <div className="text-sm font-medium text-gray-900 truncate max-w-xs">
                  {rule.name}
                </div>
                <div className="text-sm text-textPrimary">{rule.type}</div>
                <div className="text-right text-sm text-textPrimary">
                  <div className="flex items-center justify-center">
                    <span className="mr-2">🕒</span>
                    {rule?.lastTriggered || "Never"}
                  </div>
                </div>
                <div className="text-right text-sm text-textPrimary">
                  <div className="flex items-center justify-center">
                    <span className="mr-2">✏️</span>
                    {rule?.updatedAt
                      ? `${calculateHoursAgo(rule.updatedAt)} hours ago`
                      : "-"}
                  </div>
                </div>
                <div className="text-center text-sm text-textPrimary">
                  <ToggleButton
                    isActive={rule?.status}
                    isLoading={togglingRules[rule._id]}
                    onToggle={(event) => {
                      event.stopPropagation();
                      toggleStatus(rule._id);
                    }}
                  />
                </div>
                <div className="text-center text-sm text-textPrimary">
                  <button
                    onClick={(event) => handleDelete(rule._id, event)}
                    className="text-red-600 hover:text-red-800 focus:outline-none"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleTable;
