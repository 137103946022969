import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import KnowledgeBaseCreationForm from "../components/KnowledgeBaseCreationForm";
import TopBarContent from "./components/TopBarContent"; // Ensure TopBarContent is appropriately adapted
import { useNavigate } from "react-router-dom";
import { useKnowledgeBaseStore } from "../../../../store"; // Adjust path and store for knowledge base
import { createKnowledgeBaseEntryApi } from "../../../../api/knowledgeBaseApi"; // Assume similar API setup for knowledge base
import { toast } from "react-toastify";
import { shallow } from "zustand/shallow";
import {
  getPresignedUrl,
  uploadFileToS3,
} from "api/awsApi.js";

const CreateKnowledgeBaseEntry = () => {
  const navigate = useNavigate();
  const {
    form,
    resetForm,
    fetchKnowledgeBases,
    checkFormFilled,
    files,
    resetFiles,
  } = useKnowledgeBaseStore((state) => ({
    form: state.form,
    resetForm: state.resetForm,
    fetchKnowledgeBases: state.fetchKnowledgeBases,
    checkFormFilled: state.checkFormFilled,
    files: state.files,
    resetFiles: state.resetFiles,
  }), shallow);
  const handleFileUploadParams = async (params) => {
    if (Object.keys(files).length === 0) return;
    try {
      for (const fileId in files) {
        const fileObj = files[fileId];
        const { url, key } = await getPresignedUrl(fileObj.key);
        await uploadFileToS3(url, fileObj.file);
      }
    } catch (error) {
      console.log(error, "---error");
      toast.error("Error uploading files");
      throw error;
    }
  };
  const handleSaveAfterUpload = async () => {
    try {
      const isFilled = checkFormFilled();
      if (!isFilled) {
        return;
      }
      await handleFileUploadParams(form.parameters);
      await createKnowledgeBaseEntryApi(form);
      resetForm();
      await fetchKnowledgeBases(); // Assuming you have a method to fetch entries like fetchRules
      toast.success("Knowledge base is being created");
      navigate("/dashboard/knowledgeBase"); // Adjusted navigation path

    } catch {
      console.log("error in creating knowledge base entry");
      toast.error("Error in creating knowledge base entry");
    }
  };

  useEffect(() => {
    return () => {
      resetForm();
    }
  }, [])




  return (
    <>
      <TopBar>
        <TopBarContent handleSaveEntry={handleSaveAfterUpload} />
      </TopBar>
      <KnowledgeBaseCreationForm />
    </>
  );
};

export default CreateKnowledgeBaseEntry;
