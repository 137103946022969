import baseApi from "./baseApi";
import { useAuthStore } from "../store/authStore";

export const updateUserInfoApi = async () => {
  try {
    const token = useAuthStore.getState().token;
    const response = await baseApi.get("/user/info", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
  }
};

export const login = async (email, password) => {
  try {
    const response = await baseApi.post("/auth/login", { email, password });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
  }
};

export const googleLogin = async (token) => {
  try {
    const response = await baseApi.post("/auth/login/google", { token });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
  }
};

export const register = async (email, name, password) => {
  try {
    const response = await baseApi.post("/auth/register", {
      email,
      name,
      password,
    });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
  }
};

export const googleRegister = async (token) => {
  try {
    const response = await baseApi.post("/auth/register/google", { token });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
  }
};

export const validateUserAuth = async () => {
  try {
    const token = useAuthStore.getState().token;
    const response = await fetch(`${process.env.REACT_APP_MACHINE_SERVER_URL}/api/account/check`, {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const error = new Error('HTTP error');
      error.status = response.status;
      error.statusText = response.statusText;
      error.response = response;
      throw error;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
