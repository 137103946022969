import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import RuleCreationForm from "../components/RuleCreationForm";
import RuleToools from "../components/RuleTools";
import RuleCategoryForm from "../components/RuleCategoryForm";
import TopBarContent from "./components/TopBarContent";
import { useNavigate } from "react-router-dom";
import { useRulesStore } from "../../../../store";
import { createRuleApi } from "../../../../api/ruleApi";
import TestRule from "../components/TestRule";
import { toast } from "react-toastify";
const CreateRule = () => {
  const navigate = useNavigate();
  const { form, resetForm, fetchRules, checkFormFilled } = useRulesStore();
  const handleSaveRule = async () => {
    try {
      const isFilled = checkFormFilled();
      if (!isFilled) {
        return;
      }
      await createRuleApi(form);
      resetForm();
      await fetchRules();
      navigate("/dashboard/rules");
    } catch (err) {
      toast.error("Error in creating rule");
      console.log(err);
    }
  };
  const [open, setOpen] = useState(false);
  const handleSideBarClose = () => {
    setOpen(false);
  };
  const handleTestRule = () => {
    setOpen(!open);
  };
  return (
    <div className="flex flex-col min-h-screen">
      <TestRule open={open} onClose={handleSideBarClose} />
      <TopBar>
        <TopBarContent
          handleSaveRule={handleSaveRule}
          handleTestRule={handleTestRule}
        />
      </TopBar>
      <div className="flex-grow overflow-auto">
        <RuleCreationForm />
        {/* <RuleCategoryForm /> */}
        <RuleToools />
      </div>
    </div>
  );
};

export default CreateRule;