import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import KnowledgeBaseCreationForm from "./components/KnowledgeBaseCreationForm"; // Updated to KnowledgeCreationForm
import TopBarContent from "./components/TopBarContent";
import { useNavigate, useParams } from "react-router-dom";
import { useKnowledgeBaseStore } from "../../../../store"; // Updated to KnowledgeBaseStore
import { shallow } from "zustand/shallow";

const ViewKnowledgeBaseEntry = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { knowledgeBases, resetForm, updateFullForm } = useKnowledgeBaseStore(
    (state) => ({
      knowledgeBases: state.knowledgeBases,
      resetForm: state.resetForm,
      updateFullForm: state.updateFullForm,
    }),
    shallow
  ); // Updated to KnowledgeBaseStore



  useEffect(() => {
    if (id === undefined || id === "") {
      resetForm();
      navigate("/dashboard/knowledgeBase");
    }
    const entry = knowledgeBases?.find((entry) => entry._id === id);
    if (entry) {
      updateFullForm(entry);
    } else {
      resetForm();
      navigate("/dashboard/knowledgeBase");
    }
    return () => {
      resetForm();
    };
  }, [id, knowledgeBases, navigate, resetForm, updateFullForm]);

  const [open, setOpen] = useState(false);
  const handleSideBarClose = () => {
    setOpen(false);
  };
  const handleTestEntry = () => {
    setOpen(!open);
  };

  return (
    <>
      <TopBar>
        <TopBarContent handleTestEntry={handleTestEntry} />{" "}
      </TopBar>
      <KnowledgeBaseCreationForm /> {/* Updated form component */}
    </>
  );
};

export default ViewKnowledgeBaseEntry;
