import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KNOWLEDGE_BASE_FILE_TYPES } from "utils/constant.js";
import { useKnowledgeBaseStore } from "store/knowledgeBaseStore.js";
import { shallow } from "zustand/shallow";
const FileUpload = ({ handleFileChange }) => {
  const fileUploadId = useRef(`file-upload-${Date.now()}`);
  const { files, updateFiles, removeFile } = useKnowledgeBaseStore(state => ({
    files: state.files,
    updateFiles: state.updateFiles,
    removeFile: state.removeFile,
  }), shallow);
  const fileChange = (event) => {
    const selectedFile = event.target.files[0];
    const validFileTypes = KNOWLEDGE_BASE_FILE_TYPES;
    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("File type not supported.");
      event.target.value = null;
      return;
    }
    const fileKey = `uploads/knowledgeBase/${Date.now()}_${selectedFile.name.replace(/\s+/g, '_')}`;
    updateFiles(fileUploadId.current, { key: fileKey, file: selectedFile });
    handleFileChange(fileKey);
  };

  useEffect(() => {
    return () => {
      removeFile(fileUploadId.current);
    }
  }, [])
  useEffect(() => {
    console.log(files, "---files");
  }, [files])

  return (
    <div className="flex justify-center p-5">
      <input type="file" onChange={fileChange} />
    </div>
  );
};

export default FileUpload;
