import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import KnowledgeBaseTable from "../components/KnowledgeBaseTable";
import TopBarContent from "./components/TopBarContent";
import { useNavigate } from "react-router-dom";
import { useKnowledgeBaseStore } from "../../../../store";
import { deleteKnowledgeBaseEntryApi } from "../../../../api/knowledgeBaseApi";
import { toast } from "react-toastify";
import { shallow } from "zustand/shallow";

const KnowledgeBaseDisplay = () => {
  const navigate = useNavigate();
  const { knowledgeBases, fetchKnowledgeBases } = useKnowledgeBaseStore(
    (state) => ({
      knowledgeBases: state.knowledgeBases,
      fetchKnowledgeBases: state.fetchKnowledgeBases,
    }),
    shallow
  );

  const handleOnClick = (id) => {
    navigate(`/dashboard/knowledgeBase/view/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteKnowledgeBaseEntryApi(id);
      await fetchKnowledgeBases();
      toast.success("Knowledge base entry deleted successfully");
    } catch (error) {
      toast.error(error.message || "Failed to delete knowledge base entry");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchKnowledgeBases();
    };

    const interval = setInterval(() => {
      if (knowledgeBases.some(kb => kb.status === 'CREATING')) {
        fetchData();

      } else {
        clearInterval(interval);

      }
    }, 5000);

    return () => clearInterval(interval);
  }, [knowledgeBases, fetchKnowledgeBases]);

  return (
    <>
      <TopBar>
        <TopBarContent />
      </TopBar>
      <KnowledgeBaseTable
        handleOnClick={handleOnClick}
        handleDelete={handleDelete}
        entries={knowledgeBases}
      />
    </>
  );
};

export default KnowledgeBaseDisplay;