import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "tailwindcss/tailwind.css"; // Import Tailwind CSS
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PageNotFound from "components/pageNotFound";
import { useAuthStore } from "./store/authStore";
import Dashboard from "./pages/Dashboard";
// import Onboarding from "./pages/Onboarding";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const auth = useAuthStore((state) => state);
  console.log(auth.isAuthenticated);
  useEffect(() => {
    auth.updateUserInfo();
  }, []);
  return (
    <>
      <ToastContainer defaultToastOptions={{ duration: 1000 }} />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            !auth.isAuthenticated ? <Login /> : <Navigate to="/dashboard" />
          }
        />
        <Route
          path="/signup"
          element={
            !auth.isAuthenticated ? <Signup /> : <Navigate to="/dashboard" />
          }
        />

        <Route
          path="/dashboard/*"
          element={
            auth.isAuthenticated ? (
              <Dashboard />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};
export default Layout;
